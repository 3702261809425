import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import VueConfetti from "vue-confetti";
import router from "./router";
import store from "./store";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

Vue.use(VueConfetti);
const firebaseConfig = {
  apiKey: "AIzaSyCW1T1bIoxxNSMZFtcSnyMBqGW2Xvw5i74",
  authDomain: "discgolf-bingo.firebaseapp.com",
  projectId: "discgolf-bingo",
  storageBucket: "discgolf-bingo.appspot.com",
  messagingSenderId: "434909224641",
  appId: "1:434909224641:web:24093f449e80be6a067e40",
  measurementId: "G-HQSJD9V1D6",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;
firebase.auth().onAuthStateChanged((user) => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

export const db = firebase.firestore();
