<template>
  <div class="container">
    <div class="row">
      <nav class="col-12">
        <ul>
          <li><router-link :to="{ name: 'Start' }">Home</router-link></li>
          <li><router-link :to="{ name: 'About' }">About</router-link></li>
          <li>
            <router-link :to="{ name: 'Play' }">Play</router-link>
          </li>
          <li class="right" v-if="!isUserLoggedIn">
            <router-link :to="{ name: 'Register' }">Register</router-link>
          </li>
          <li v-if="!isUserLoggedIn">
            <router-link :to="{ name: 'Login' }">Login</router-link>
          </li>
          <li @click="logout()" v-if="isUserLoggedIn" class="right">Logout</li>
        </ul>
      </nav>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Home",
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "Start",
          });
        });
    },
  },
  computed: {
    isUserLoggedIn() {
      let loggedIn = false;
      if (firebase.auth().currentUser) {
        loggedIn = true;
      }
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          loggedIn = true;
        } else {
          loggedIn = false;
        }
      });

      return loggedIn;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
nav {
  display: flex;
  width: 100%;
  margin: 0;

  ul {
    display: flex;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 10px 0 0;
    justify-content: space-between;

    li {
      font-size: 14px;
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: 0.1em;

      a {
        color: white;
        text-decoration: none;
        transition: all ease 0.3s;
      }
    }
  }
}
/* SMARTPHONES PORTRAIT */
@media only screen and (min-width: 300px) {
}

/* SMARTPHONES LANDSCAPE */
@media only screen and (min-width: 480px) {
}

/* TABLETS PORTRAIT */
@media only screen and (min-width: 768px) {
}

/* TABLET LANDSCAPE / DESKTOP */
@media only screen and (min-width: 1024px) {
  nav {
    ul {
      justify-content: flex-start;

      li {
        margin-right: 30px;

        &.right {
          margin-left: auto;
        }
      }
    }
  }
}
</style>
