import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import { db } from "@/main";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    usersBingoCard: null,
    usersActiveBingoCards: [],
    bingoCardsLoaded: false,
  },
  getters: {
    bingoCardsLoaded: (state) => {
      return state.bingoCardsLoaded;
    },
    usersActiveBingoCards: (state) => {
      return state.usersActiveBingoCards;
    },
    usersBingoCard: (state) => {
      return state.usersBingoCard;
    },
    usersHasBingoCard: (state) => {
      return state.usersBingoCard !== null;
    },
  },
  mutations: {
    userGotBingo(state) {
      state.usersBingoCard.bingo = true;
    },
    async fetchBingoCards(state) {
      return db
        .collection("bingoCards")
        .where("owner", "==", firebase.auth().currentUser.uid)
        .where("expired", "==", false)
        .get()
        .then((result) => {
          let userBingoCards = [];

          result.forEach((doc) => {
            userBingoCards.push({ card: doc.data(), id: doc.id });
          });

          state.usersActiveBingoCards = userBingoCards;
          state.bingoCardsLoaded = true;
        });
    },
  },
  actions: {
    fetchBingoCards(context) {
      context.commit("fetchBingoCards");
    },
    userGotBingo(context) {
      context.commit("userGotBingo");
    },
    createBingoCard(context) {
      context.commit("createBingoCard");
    },
  },
  modules: {},
});
